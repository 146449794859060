import { ValidationErrors } from '@angular/forms';

export class ErrorMessage {
  static getErrorMessage(error: ValidationErrors | any): string {
    const errorKey = Object.keys(error)[0];
    switch (errorKey) {
      case 'minlength':
        return 'จำนวนตัวอักษรขั้นต่ำ ' + error.minlength.requiredLength + ' ตัวอักษร';
      case 'maxlength':
        return 'จำนวนตัวอักษรไม่เกิน ' + error.maxlength.requiredLength + ' ตัวอักษร';
      case 'min':
        return 'จำนวนต้องไม่น้อยกว่า ' + Number(error.min.min).toLocaleString('en-US');
      case 'max':
        return 'จำนวนต้องไม่เกิน ' + Number(error.max.max).toLocaleString('en-US');
      case 'required':
        return 'กรุณากรอกข้อมูล';
      case 'duplicate':
        return 'VALIDATION.DUPLICATE';
      case 'email':
        return 'รูปแบบอีเมลไม่ถูกต้อง';
      case 'mustMatch':
        return 'รหัสผ่านและยืนยันรหัสผ่านไม่ตรงกัน';
      case 'phoneInvalid':
        return 'รูปแบบเบอร์โทรไม่ถูกต้อง';
      case 'hasCapitalCase':
      case 'has_upper_case':
      case 'hasSmallCase':
      case 'has_lower_case':
      case 'hasNumber':
      case 'has_number':
      case 'hasSpecialCharacters':
      case 'has_special_characters':
        return 'รูปแบบรหัสผ่านไม่ถูกต้อง';
      case 'postcode_length':
        return 'ตัวเลขต้องมีจำนวน 5 หลัก';
      case 'etax_length':
        return 'ตัวเลขต้องมีจำนวน 5 หลัก';
      case 'maxExpireLimit':
        return 'จำนวนวันต้องไม่เกิน 1095';
      default:
        return this.getServerErrorMessage(error);
    }
  }

  static getServerErrorMessage(value: any): string {
    if (Array.isArray(value)) {
      const error = this.getErrorMessage(value[0]);
      if (!Array.isArray(error)) {
        return error;
      } else {
        return this.getServerErrorMessage(error);
      }
    } else {
      return this.getServerErrorKey(`${value}`);
    }
  }

  static getServerErrorKey(value: string) {
    switch (value) {
      case 'has already been taken':
        return 'ข้อมูลนี้มีอยู่ในระบบแล้ว';
      default:
        return value;
    }
  }
}
